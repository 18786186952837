import React, {useEffect, useRef} from 'react';
import {Modal} from 'bootstrap';
import {createRoot} from "react-dom/client";
import vhCheck from "vh-check";

const vh = vhCheck()

const App = () => {
    useEffect(() => {
        new Modal(document.getElementById('login-modal'), {backdrop: false, keyboard: false}).show()
    })
    const user = useRef(null)
    const password = useRef(null)

    const login = () => {
        const target = "/sa/index.html" + window.location.hash
        const username = user.current.value;
        fetch("/sa/login", {method: "POST", headers: {"Content-Type": "application/json"},
             body: JSON.stringify({username, password: password.current.value})})
             .then(() => {
                 window.location.href = target
             })
    }

    return (<div className="d-flex flex-column main-box position-relative">
        <img src="/sa/img/login/login-bg.webp" alt="" style={{ display: "none" }}
             onLoad={() => document.body.style.backgroundImage = "url('/sa/img/login/login-bg.webp')"}/>
        <img src="/sa/img/login/slice1.svg" alt="" style={{position: "absolute", top:"0", height:"100vh", left:"0"}}/>
        <img src="/sa/img/login/slice2.svg" alt="" style={{position: "absolute", top:"0", height:"100vh", right:"0"}}/>
        <img src="/sa/img/login/SAPPartnerLogo.svg" alt="SAP Partner"
             style={{position: "absolute", bottom:(16+vh.value) + "px", right:"16px", width: "150px"}}/>

        <div className="flex-grow-1" >
            <div className="modal" tabIndex="-1" id="login-modal">
                <div className="modal-dialog modal-dialog-centered" >
                    <div className="modal-content" style={{borderRadius: "0", border: "none", background: "linear-gradient(to bottom,rgba(255,255,255,1),rgba(255,255,255,0.7))"}}>
                        <div className="modal-body">
                            <div className="d-flex flex-column align-items-center">
                                <div className="d-flex align-items-center mb-4 mt-2">
                                    <div className="milkman" style={{fontSize: "30px", width: "144px"}}>milkman</div>
                                    <img alt="milkman logo" width="45" height="45" src="/sa/img/logo1.svg" style={{marginTop: "-2px"}}/>
                                    <span className="ms-2 text-nowrap user-select-none pt-1" style={{fontSize: "30px", color: "#454545"}}>{"Smart\u2006Promise"}</span>
                                </div>
                            </div>
                            <form className="mx-3">
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label fst-normal ms-2">User</label>
                                    <input ref={user} type="text" className="form-control" id="username"/>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label fst-normal ms-2">Password</label>
                                    <input ref={password} type="password" className="form-control" id="password"/>
                                </div>
                            </form>
                            <div className="d-flex pt-4 mb-3">
                                <span className="flex-grow-1"/>
                                <button type="button"  style={{width: "150px"}} className="btn btn-primary me-3" onClick={login}>Log In</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

createRoot(document.getElementById('app')).render(<App/>)